import { ReactNode } from "react";
import authClient from "@coxauto-ui/core/auth/get-auth-client-tenant-admin";
import { deriveEnvironmentName } from "@coxauto-ui/ccxm-util-derive-environment";

import { LogLevel } from "@microsoft/signalr";
import { SignalRProvider } from "@coxauto-ui/core/real-time-signalr";

export interface CcxmInsightsListRealTimeProps {
  children: ReactNode;
}
const baseUrls = {
  nonprod: "https://cxm-realtime-api.np-pipelines.app.coxautoinc.com/",
  prod: "https://cxm-realtime-api.pipelines.app.coxautoinc.com/"
};

const logLevelByEnv = {
  nonprod: LogLevel.Trace,
  prod: LogLevel.None
};
const env = deriveEnvironmentName();
const url = baseUrls[env];
const logLevel = logLevelByEnv[env];
export function CcxmInsightsListRealTime({
  children
}: CcxmInsightsListRealTimeProps) {
  return (
    <SignalRProvider
      accessTokenFactory={() => authClient.getAuth()}
      logLevel={logLevel}
      url={`${url}Hubs/Deals`}
    >
      <div data-testid="ccxm-insights-list-real-time">{children}</div>
    </SignalRProvider>
  );
}

export default CcxmInsightsListRealTime;
