import { AttachmentTypeEnum } from "./conversation";

export interface SendCommunicationAttachmentResponse {
  key: string;
  contentType: string;
  fileName: string;
  fileSize: number;
  token: string;
  url?: string;
}
export interface GetCommunicationAttachmentRequest {
  coxAutoMessageId: number;
  consumerId: string;
  attachmentId: string;
  XSignature: string;
}

export function getAttachmentType(contentType: string): AttachmentTypeEnum {
  if (contentType.toLowerCase().includes("image/")) {
    return AttachmentTypeEnum.IMAGE;
  } else if (contentType.toLowerCase().includes("video/")) {
    return AttachmentTypeEnum.VIDEO;
  } else {
    return AttachmentTypeEnum.OTHER;
  }
}

export const supportedAudioExtensions = ["mp3", "mp4", "wav", "ogg"];
