import "antd/dist/antd.css";
import "./app.scss";
import { useMemo } from "react";
import { AddScriptTag } from "@coxauto-ui/core-helpers";
// Todo we need to delete this when we finish the migration to interstate
import { ToastContainer as CXToastContainer } from "@coxauto-ui/core-cx";
import { TenantAdminViewAsBanner } from "@coxauto-ui/tenant/admin";
import { createGlobalStyle } from "styled-components";
import Routes from "./routes/routes";
import { deriveEnvironmentName } from "@coxauto-ui/ccxm/util/derive-environment";
import {
  BridgeBarEnvironment,
  BridgeBarScriptProps,
  getBridgeBarScript
} from "@coxauto-ui/ccxm/util/get-bridge-bar-script";
import { ToastContainer } from "@interstate/components/Toast";
import AppWrapper from "./app-wrapper";
import getSessionId from "@coxauto-ui/util/get-session-identifier";
import { LDProvider, LDReactOptions } from "launchdarkly-react-client-sdk";
import { getActiveFlags } from "@coxauto-ui/core/third-party/launch-darkly";
import { shallowEqual, useSelector } from "react-redux";
import { getUserProfileState } from "@coxauto-ui/ccxm/store";

const GlobalStyle = createGlobalStyle`
  .MuiPopper-root {
    z-index: 4;
  }
  .Toastify__toast-container {
    transform: translate(0,18px);
  }
  body {
    background-color: #fff;
  }
`;

const reactOptions: LDReactOptions = {
  useCamelCaseFlagKeys: false
};

const flags = getActiveFlags("cxm");

const clientSideID =
  document.domain && document.domain.includes("localhost")
    ? "63583be10d275b11a1f32f7b"
    : (document.domain && document.domain.includes("ccxmnp")) ||
      (document.domain && document.domain.includes("np-pipelines"))
    ? "63583be10d275b11a1f32f7b"
    : "63583be10d275b11a1f32f7c";

export function App() {
  const { bridgeUserId, bridgeUserName, dealerGroupId, dealerGroupName } =
    useSelector(getUserProfileState, shallowEqual);

  const bridgeScript = useMemo(() => {
    const sessionId = getSessionId();

    const getBridgeBarEnv = () => {
      const env = deriveEnvironmentName();
      const mapEnv: Record<string, BridgeBarEnvironment> = {
        prod: "prod",
        nonprod: "qa"
      };
      return mapEnv[env] ?? "qa";
    };

    const config: BridgeBarScriptProps = {
      sessionId,
      environment: getBridgeBarEnv(),
      solutionGroup: "CMD"
    };

    return getBridgeBarScript(config);
  }, []);

  const user = useMemo(() => {
    return {
      key: bridgeUserId,
      name: bridgeUserName,
      custom: {
        dealerGroupId,
        dealerGroupName
      }
    };
  }, [bridgeUserId, bridgeUserName, dealerGroupId, dealerGroupName]);

  return (
    <AppWrapper>
      <LDProvider
        clientSideID={clientSideID}
        flags={flags}
        reactOptions={reactOptions}
        user={user}
      >
        <bridge-bar data-testid="bridge-bar" />
        <AddScriptTag
          async
          src="https://assets.bridge.coxautoservices.com/bridge-bar.js"
          testid="bridge-bar-script"
        />
        <AddScriptTag script={bridgeScript} testid="bridge-bar-inline-script" />
        <TenantAdminViewAsBanner />
        <Routes />
        <CXToastContainer />
        <ToastContainer />
        <GlobalStyle />
      </LDProvider>
    </AppWrapper>
  );
}

export default App;
