import { EntityState } from "@reduxjs/toolkit";
import Consumer from "./consumer";
import Deal from "./deal";
import Filter from "./filter";
import Opportunity from "./opportunity";
import Paging from "./paging";
import ViewportMode from "./viewport-mode";
import SellingVehicle from "./deal-selling-vehicle";
import TradeVehicle from "./trade-vehicle";
import AmpSellingVehicle from "./amp-selling-vehicle";

/*
  we need to restructure the interface lib. This file is too big and the other files are too small.
  changes to this lib are going to impact too many disparate pieces.
*/

export interface ConsumerInsightsRecordEntity {
  id: string;
  consumer: Consumer;
  deals: Deal[];
  opportunities: Opportunity[];
}

export interface ConsumerInsightsRecordsState
  extends EntityState<ConsumerInsightsRecordEntity> {
  filters: Filter[];
  paging: Paging;
  visibleColumns: string[];
  loadingStatus: "not loaded" | "loading" | "loaded" | "error";
  error: string | null;
  completedErrorCount: number;
}

export interface ConsumerInsightsRowsRequest {
  dealerGroupId: string;
  filters: Filter[];
  page: number;
  pageSize: number;
}

export interface OpportunitiesRowsRequest {
  filters: Filter[];
  page: number;
  pageSize: number;
}

export type Principal = "makeMyDeal" | "vinsolutions" | "commonCore";

export interface MultiTypeConsumerInsightsRowsRequest
  extends ConsumerInsightsRowsRequest {
  type: "deals" | "opportunities" | "all";
}

export interface ConsumerInsightsRowsResponse {
  visibleColumns: string[];
  filters: Filter[];
  rows: ConsumerInsightsRecordEntity[];
  paging: Paging;
}

export interface OpportunitiesRowsResponse {
  filters: Filter[];
  rows: ConsumerInsightsRecordEntity[];
  paging: Paging;
  visibleColumns: string[];
}

export interface UserPreferencesState {
  isSidebarCollapsed: boolean;
}

export interface ConfigurationState {
  customerId: string | null;
  viewportMode: ViewportMode;
}

export interface UserProfileState {
  bridgeUserId: string;
  dealerGroupId: string;
  bridgeUserName: string;
  dealerGroupName: string;
  isEmployee: boolean;
  vinUserId: number;
  viewAsUsername: string;
  loadingStatus: "not loaded" | "loading" | "loaded" | "error";
  error: string | null;
  messages: string[];
}

export interface UserProfileResponse {
  message?: string;
  userProfile: {
    bridgeUserId: string | null;
    bridgeUserName: string | null;
    dealerGroupId: string | null;
    dealerGroupName: string | null;
    vinUserId: number | null;
    isEmployee: boolean | null;
    messages: string[];
  };
}

export interface CompleteOpportunitiesRequest {
  opportunityId: string;
  markComplete: boolean;
}

export type CompleteOpportunitiesResponse = CompleteOpportunitiesRequest;

export interface AmpStandaloneResponse {
  isAmpStandalone: boolean;
}

export enum DealType {
  Cash = "Cash",
  Finance = "Finance",
  Lease = "Lease"
}

export interface DealInsightsRowsRequest {
  currentClientDateTime: string;
  textFilters: TextFilters;
  optionFilters: OptionFilters;
  page: number;
  pageSize: number;
}

export interface DealInsightsRowsResponse {
  deals: DealInsightsRecordsEntity[];
  filterSequence: FilterSequence[];
  paging: Paging;
  optionFilters: OptionFilters;
  textFilters: TextFilters;
}

export interface DealInsightsRecordsState
  extends EntityState<DealInsightsRecordsEntity> {
  error: string;
  filterSequence: FilterSequence[];
  loadingStatus: LoadingStatus;
  paging: Paging;
  optionFilters: OptionFilters;
  textFilters: TextFilters;
}

export interface DealUpdatesRecordsState {
  dealUpdates: string[];
  showDealUpdates: boolean;
}

export enum LoadingStatus {
  notLoaded = "not loaded",
  loading = "loading",
  loaded = "loaded",
  error = "error"
}

export enum EmployeeRoleType {
  unknown = "unknown",
  primarySalesperson = "primarySalesperson",
  salesManager = "salesManager",
  secondarySalesperson = "secondarySalesperson",
  otherSalesperson = "otherSalesperson",
  assistantSalesManager = "assistantSalesManager",
  closer = "closer",
  financeManager = "financeManager",
  customerService = "customerService",
  aftermarketManager = "aftermarketManager",
  deliveryManager = "deliveryManager",
  other = "other"
}

export interface Participant {
  userId: string;
  name: string;
  employeeRoleDisplayName: string;
  employeeRoleType: EmployeeRoleType;
}
export interface DealInsightsRecordsEntity {
  cobuyer: {
    firstName: string | null;
    lastName: string | null;
  } | null;
  consumerId: string;
  dealerName: string;
  dealershipId: string;
  dealId: string;
  dealType: DealType;
  employees: Participant[];
  firstName: string;
  hasMultipleDeals: boolean;
  isAccepted: boolean;
  lastName: string;
  persona: string;
  lastUpdatedDateUtc: string;
  manageDealButtonLink: string | null;
  mostRecentUpdateMessages: string[];
  sellingVehicle: SellingVehicle | null;
  tradeVehicles: TradeVehicle[];
  viewDealButtonLink: string | null;
}

export interface FilterSequence {
  categoryType: string;
  filterType: FilterSequenceFilterType;
}

export enum FilterSequenceFilterType {
  optionFilter = "optionFilter",
  rangeFilter = "rangeFilter",
  textFilter = "textFilter"
}

export interface TextFilters {
  [key: string]: {
    componentType: TextFilterComponentType;
    textInputValue: string;
  };
}

export enum TextFilterComponentType {
  searchInput = "searchInput",
  multiDeal = "multiDeal"
}

export interface OptionFilters {
  [key: string]: {
    componentType: OptionFilterComponentType;
    count: number;
    displayName: string;
    options: OptionFilterOption[];
    selectedValues: string[];
  };
}

export enum OptionFilterComponentType {
  optionList = "optionList",
  optionSingleSelect = "optionSingleSelect"
}

export interface OptionFilterOption {
  count: number;
  label: string;
  value: string;
}

export interface OpportunityInsightsRecordsEntity {
  campaign: string;
  sellingVehicle: AmpSellingVehicle | null;
  lastUpdatedDateUtc: string;
  marketLevel: string;
  offerPaymentDiff: number;
  nextServiceAppt: string;
  equity: number;
  financeType: string;
  termRemainingInMonths: number;
  opportunityId: string;
  isCompleted: boolean;
  priority: number;
  dealershipId: string;
  dealerName: string;
  consumerId: string;
  firstName: string;
  lastName: string;
}

export interface OpportunityInsightsRecordsState
  extends EntityState<OpportunityInsightsRecordsEntity> {
  error: string;
  filterSequence: FilterSequence[];
  loadingStatus: LoadingStatus;
  paging: Paging;
  optionFilters: OptionFilters;
  textFilters: TextFilters;
  visibleColumns: string[];
}

export interface OpportunitiesInsightsRowsRequest {
  textFilters: TextFilters;
  optionFilters: OptionFilters;
  page: number;
  pageSize: number;
}

export interface OpportunityInsightsRowsResponse {
  opportunities: OpportunityInsightsRecordsEntity[];
  filterSequence: FilterSequence[];
  paging: Paging;
  optionFilters: OptionFilters;
  textFilters: TextFilters;
  visibleColumns: [];
}
