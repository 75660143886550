import {
  getCommunicationsDetails,
  getErrorMessage,
  isResponseError
} from "@coxauto-ui/communications-api";
import { LoadingIndicator } from "@coxauto-ui/core-components";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import parse from "html-react-parser";
import { ConversationContext, ConversationDetailsContext } from "../context";
import {
  conversationsDetailTestId,
  itemDetailsMessageDetailSentDateId,
  itemDetailsMessageDetailSentFromId,
  itemDetailsMessageDetailSentSubjectId,
  itemDetailsMessageDetailSentTextId,
  itemDetailsMessageDetailSentToId
} from "../testIds";
// eslint-disable-next-line
import { TagManager } from "@coxauto-ui/core-third-party-ga4";
import { getDisplayableDate } from "@coxauto-ui/communications-util";
import { BaseColors } from "@coxauto-ui/communications-interfaces";
import ItemAttachment from "./item-attachment";
import { ItemDetailsBackButton } from "./item-details-back-button";

const StyledMessageDetails = styled.div`
  padding: 16px;
  flex: 1;
  overflow-y: auto;
`;

const StyledMessageDetailsLoaded = styled.div``;

const StyledSeparator = styled.hr<{ baseColors: BaseColors }>`
  display: block;
  margin-top: 6px;
  margin-bottom: 6px;
  border-top: 1px solid ${props => props.baseColors.gray100};
`;

const StyledNormalText = styled.span`
  font-weight: normal;
`;

const StyledItemAttachmentContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  column-gap: 25px;
  row-gap: 5px;
`;

export function MessageItemDetails() {
  const { customerId, activeListTab, env, baseColors } =
    useContext(ConversationContext);
  const { selectedConversationItem } = useContext(ConversationDetailsContext);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [detailText, setDetailText] = useState<string | null>(null);

  const callGetAdditionalMessageDetail = useCallback(async () => {
    if (selectedConversationItem === null || !selectedConversationItem.key) {
      return;
    }
    setLoading(true);
    const communications = await getCommunicationsDetails(
      {
        messageDetailId: selectedConversationItem?.key || 0,
        ccID: customerId
      },
      env
    );
    setLoading(false);
    if (isResponseError(communications)) {
      setError(getErrorMessage(communications));
    } else {
      setDetailText(communications);
    }
  }, [selectedConversationItem, customerId, env]);

  useEffect(() => {
    callGetAdditionalMessageDetail();
  }, [callGetAdditionalMessageDetail]);

  useEffect(() => {
    TagManager.event({
      event: "productEvent",
      eventName: "Message Details Viewed",
      eventProperties: {
        packagedBusinessCapability: "OCC Inbox",
        commonCustomerId: customerId,
        communicationChannel: activeListTab,
        replyContext: undefined,
        errorMessage: undefined
      }
    });
  }, [activeListTab, customerId]);

  const formattedSenderName = useMemo(() => {
    return selectedConversationItem?.senderName &&
      selectedConversationItem?.senderContactInfo
      ? selectedConversationItem?.senderName +
          " " +
          "(" +
          selectedConversationItem?.senderContactInfo +
          ")"
      : selectedConversationItem?.senderContactInfo
      ? "(" + selectedConversationItem?.senderContactInfo + ")"
      : "";
  }, [
    selectedConversationItem?.senderContactInfo,
    selectedConversationItem?.senderName
  ]);

  const formattedRecipientName = useMemo(() => {
    return selectedConversationItem?.recipientName &&
      selectedConversationItem?.recipientContactInfo
      ? selectedConversationItem?.recipientName +
          " " +
          "(" +
          selectedConversationItem?.recipientContactInfo +
          ")"
      : selectedConversationItem?.recipientContactInfo
      ? "(" + selectedConversationItem?.recipientContactInfo + ")"
      : "";
  }, [
    selectedConversationItem?.recipientContactInfo,
    selectedConversationItem?.recipientName
  ]);

  return (
    <StyledMessageDetails
      data-testid={conversationsDetailTestId}
      id="conversation-message-details"
    >
      {loading && (
        <LoadingIndicator
          actionName="Loading Communication Detail"
          htmlId="conversation-message-details-loading-indicator"
        />
      )}
      {error && <span>{error}</span>}
      {!error && !loading && (
        <StyledMessageDetailsLoaded>
          <ItemDetailsBackButton />
          <StyledSeparator baseColors={baseColors} />
          <b>From: </b>
          <StyledNormalText
            data-testid={itemDetailsMessageDetailSentFromId}
            id={itemDetailsMessageDetailSentFromId}
          >
            {formattedSenderName}
          </StyledNormalText>
          <br />
          <b>To: </b>
          <StyledNormalText
            data-testid={itemDetailsMessageDetailSentToId}
            id={itemDetailsMessageDetailSentToId}
          >
            {formattedRecipientName}
          </StyledNormalText>
          <br />
          <b>Date: </b>
          <StyledNormalText
            data-testid={itemDetailsMessageDetailSentDateId}
            id={itemDetailsMessageDetailSentDateId}
          >
            {selectedConversationItem != null
              ? getDisplayableDate(new Date(selectedConversationItem.date))
              : ""}
          </StyledNormalText>
          <br />
          <br />
          {selectedConversationItem?.subject ? (
            <b
              data-testid={itemDetailsMessageDetailSentSubjectId}
              id={itemDetailsMessageDetailSentSubjectId}
            >
              Subject: {selectedConversationItem?.subject}
            </b>
          ) : null}
          <StyledSeparator baseColors={baseColors} />
          {selectedConversationItem?.attachments &&
            selectedConversationItem.attachments.length > 0 && (
              <>
                <StyledItemAttachmentContainer>
                  {selectedConversationItem.attachments.map(attachment => (
                    <div key={attachment.attachmentId}>
                      <ItemAttachment
                        attachmentId={attachment.attachmentId}
                        attachmentName={attachment.fileName}
                        attachmentType={attachment.type}
                        attachmentUrl={attachment.url}
                        coxAutoMessageId={selectedConversationItem.key}
                        date={selectedConversationItem.date}
                        simple={true}
                      />
                    </div>
                  ))}
                </StyledItemAttachmentContainer>
                <StyledSeparator baseColors={baseColors} />
              </>
            )}
          <StyledNormalText
            data-testid={itemDetailsMessageDetailSentTextId}
            id={itemDetailsMessageDetailSentTextId}
          >
            {parse(detailText ? detailText : "")}
          </StyledNormalText>
        </StyledMessageDetailsLoaded>
      )}
    </StyledMessageDetails>
  );
}

export default MessageItemDetails;
