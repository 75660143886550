import {
  Conversation,
  Environment
} from "@coxauto-ui/communications-interfaces";
import { fetchWrapper } from "@coxauto-ui/core-http";
import { getBaseUrl, getHeader } from "../config";
import { ResponseError } from "./interfaces/api-error";
import { GetCommunicationsRequest } from "./interfaces/get-communications";

const defaultResultCount = 500;
const defaultStartingPoint = 1;

export async function getCommunications(
  data: GetCommunicationsRequest,
  env: Environment
): Promise<Conversation | ResponseError> {
  const postData = {
    requestedCount: data.requestedCount || defaultResultCount,
    startingPoint: data.startingPoint || defaultStartingPoint,
    coxAutoPartyId: data.customerId.toString()
  };
  const url = `${getBaseUrl(env)}CxM/message-details`;

  const header = await getHeader();

  const response = await fetchWrapper.postAsync<Conversation>(
    url,
    postData,
    header
  );
  if (response && response.data && !response.hasError) {
    return response.data;
  } else {
    const error: ResponseError = {
      isError: true,
      statusCode: response.statusCode,
      errorResult: response.error
    };
    return error;
  }
}
