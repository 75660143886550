// Todo when we finish the interstate migration, this file should be the index.tsx file
import { useEffect, useState } from "react";
import { NewRelic } from "@coxauto-ui/core-third-party-newrelic";
import {
  LoadingIndicator as InterLoadingIndicator,
  LoadingIndicatorProps as InterLoadingIndicatorProps
} from "@interstate/components/LoadingIndicator";

export interface LoadingIndicatorInterProps
  extends Omit<InterLoadingIndicatorProps, "size"> {
  /** Action name that will be logged to NewRelic. Otherwise htmlId is used */
  actionName?: string;
  htmlId: string;
  size?: "small" | "large" | "default";
}

export function LoadingIndicatorInter(props: LoadingIndicatorInterProps) {
  const [startTime] = useState(new Date());
  const { size, ...rest } = props;
  const newSize = size === "large" ? "default" : size;
  useEffect(() => {
    return () => {
      const endTime = new Date();
      const duration = endTime.getTime() - startTime.getTime();
      const actionName = getActionName(props.htmlId, props.actionName);
      NewRelic.addPageAction("Loading Spinner Finished", {
        Duration: duration,
        Action: actionName
      });
    };
  }, [props.htmlId, props.actionName, startTime]);
  return <InterLoadingIndicator size={newSize} {...rest} />;
}
function getActionName(htmlId: string, actionName: string | undefined): string {
  if (actionName) return actionName;
  if (htmlId.endsWith("loading")) return htmlId;
  return htmlId + "-loading";
}
