import { interstateThemeRegistry } from "@interstate/components/InterstateTheming";
import { InterstateThemeProvider } from "@interstate/components/InterstateThemeProvider";
import { LDProvider, LDReactOptions } from "launchdarkly-react-client-sdk";
import { getActiveFlags } from "@coxauto-ui/core/third-party/launch-darkly";
import { useMemo } from "react";
import { Provider, shallowEqual, useSelector } from "react-redux";
import { getUserProfileState } from "@coxauto-ui/ccxm/store";
import { RoutingContextProvider } from "@coxauto-ui/ccxm-routing";
import { createGlobalStyle } from "styled-components";
import { adminStore } from "@coxauto-ui/tenant/store";
import { ToastContainer } from "@interstate/components/Toast";

/* eslint-disable-next-line */
export interface TenantAdminAppProps {
  children: React.ReactNode;
  useHostStore?: boolean;
}

const GlobalStyle = createGlobalStyle`
  body {
    background-color: #f5f6f7 !important;
  }
  .Toastify__toast-container {
    transform: translate(0,18px);
  }
  `;

const themeRegistries = [interstateThemeRegistry];

const clientSideID =
  document.domain && document.domain.includes("localhost")
    ? "63583be10d275b11a1f32f7b"
    : (document.domain && document.domain.includes("ccxmnp")) ||
      (document.domain && document.domain.includes("np-pipelines"))
    ? "63583be10d275b11a1f32f7b"
    : "63583be10d275b11a1f32f7c";

const flags = getActiveFlags("cxm");

const reactOptions: LDReactOptions = {
  useCamelCaseFlagKeys: false
};

export function TenantAdminApp({
  children,
  useHostStore = false
}: TenantAdminAppProps) {
  const { bridgeUserId, bridgeUserName, dealerGroupId, dealerGroupName } =
    useSelector(getUserProfileState, shallowEqual);

  const user = useMemo(() => {
    return {
      key: bridgeUserId,
      name: bridgeUserName,
      custom: {
        dealerGroupId,
        dealerGroupName
      }
    };
  }, [bridgeUserId, bridgeUserName, dealerGroupId, dealerGroupName]);

  const app = (
    <InterstateThemeProvider
      applicationName="CRW"
      scopeName="CRW"
      themeRegistries={themeRegistries}
    >
      <RoutingContextProvider>
        <LDProvider
          clientSideID={clientSideID}
          flags={flags}
          reactOptions={reactOptions}
          user={user}
        >
          {children}
        </LDProvider>
      </RoutingContextProvider>
      <GlobalStyle />
      <ToastContainer />
    </InterstateThemeProvider>
  );

  return useHostStore ? app : <Provider store={adminStore}>{app}</Provider>;
}

export default TenantAdminApp;
