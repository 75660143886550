import { useEffect } from "react";
import { useRoutes } from "react-router-dom";
import { getRouteDefinitions } from "./utilities/get-route-definitions";
import { getPageTitle } from "./utilities/get-page-title";
import { useRoutingLocation } from "@coxauto-ui/ccxm-routing";

type RouteDefinition = {
  path: string;
  title?: string;
};

export interface SetPageTitlesProps {
  routes: RouteDefinition[];
  defaultTitle: string;
}

export function Routes() {
  const routes = useRoutes(getRouteDefinitions());
  const location = useRoutingLocation();

  useEffect(() => {
    document.title = getPageTitle(location.pathname);
  }, [location.pathname]);

  return routes;
}

export default Routes;
