import {
  AttachmentTypeEnum,
  BaseColors,
  supportedAudioExtensions
} from "@coxauto-ui/communications-interfaces";
import { IconFileDownload } from "@coxauto-ui/core-cx";
import styled from "styled-components";
import AttachmentIcon from "../core/channel-type-attachment-icon";
import {
  messageItemAttachmentIcon,
  messageItemAttachmentName
} from "../testIds";
import {
  getCommunicationAttachment,
  getErrorMessage,
  isResponseError
} from "@coxauto-ui/communications-api";
import { Suspense, useCallback, useContext, useState } from "react";
import { ConversationContext, ConversationDetailsContext } from "../context";
import { LoadingIndicator } from "@coxauto-ui/core-components";
import { PaperClipIcon } from "@interstate/components/Icons";
import React from "react";
import { toast } from "@interstate/components/Toast";
const ItemAttachmentViewLazy = React.lazy(
  () => import("./item-attachment-view")
);
const ItemAttachmentPlayAudioLazy = React.lazy(
  () => import("./item-attachment-play-audio")
);

export const StyledMessageAttachment = styled.div`
  display: flex;
  align-items: center;
`;
export const StyledAttachmentIconContainer = styled.div<{
  baseColors: BaseColors;
}>`
  background-color: ${props => props.baseColors.blue700};
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height 40px;
  padding: 12px;
  border-radius: 4px 0px 0px 4px;
  width: 40px;
  flex: none;
  order: 0;
  flex-grow: 0;
`;
export const StyledAttachmentInfoContainer = styled.div`
  width: 100%;
  margin-right: 12px;
  margin-left: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
`;
export const StyledAttachmentName = styled.span`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 90%;
`;

export const StyledSimpleAttachmentContainer = styled.div<{
  baseColors: BaseColors;
}>`
  display: flex;
  align-items: center;
  color: ${props => props.baseColors.blue700};
`;

export const StyledSimpleAttachmentIconContainer = styled.div`
  margin-right: 6px;
  display: flex;
`;

const StyledSimpleAttachmentName = styled.span<{
  baseColors: BaseColors;
}>`
  color: ${props => props.baseColors.blue700};
  margin-left: auto;
  cursor: pointer;
  text-decoration: none;
`;

export interface ItemAttachmentProps {
  attachmentId: string;
  attachmentType: AttachmentTypeEnum;
  attachmentName: string;
  coxAutoMessageId: number;
  date: string;
  simple?: boolean;
  attachmentBubbleClassName?: string;
  attachmentViewClassName?: string;
  attachmentUrl?: string;
}

export function ItemAttachment({
  attachmentId,
  attachmentType,
  attachmentName,
  coxAutoMessageId,
  simple = false,
  date,
  attachmentBubbleClassName = "",
  attachmentViewClassName = "",
  attachmentUrl
}: ItemAttachmentProps) {
  const { customerId, setError, env, baseColors } =
    useContext(ConversationContext);
  const { xSignature } = useContext(ConversationDetailsContext);
  const [isLoading, setIsLoading] = useState(false);

  const getBlob = useCallback(async () => {
    if (coxAutoMessageId < 0) {
      toast.error("This message is still being processed, please try later.");
      return undefined;
    } else {
      setIsLoading(true);
      const resultBinary = await getCommunicationAttachment(
        {
          attachmentId,
          consumerId: customerId,
          coxAutoMessageId,
          XSignature: xSignature
        },
        env
      );

      if (isResponseError(resultBinary)) {
        setError(getErrorMessage(resultBinary));
        setIsLoading(false);
        return undefined;
      } else {
        setIsLoading(false);
        return resultBinary;
      }
    }
  }, [attachmentId, customerId, coxAutoMessageId, xSignature, env, setError]);
  const attachmentHandler = useCallback(async () => {
    if (attachmentType == AttachmentTypeEnum.VIDEO && attachmentUrl) {
      // Outbound videos will have a URL to reference.
      const link = Object.assign(document.createElement("a"), {
        target: "_blank",
        rel: "noreferrer",
        href: attachmentUrl
      });

      link.click();
      return;
    }
    const resultBinary = await getBlob();
    if (resultBinary === undefined) return;
    const url = window.URL.createObjectURL(
      new Blob([new Uint8Array(resultBinary)])
    );
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", attachmentName);
    const clickHandler = () => {
      setTimeout(() => {
        window.URL.revokeObjectURL(url);
        window.removeEventListener("click", clickHandler);
      }, 150);
    };
    link.addEventListener("click", clickHandler, false);
    document.body.appendChild(link);
    link.click();
  }, [getBlob, attachmentName]);
  return (() => {
    const extension = attachmentName?.split(".").pop()?.toLowerCase() ?? "*";

    if (simple) {
      return (
        <StyledSimpleAttachmentContainer
          baseColors={baseColors}
          onClick={attachmentHandler}
        >
          <StyledSimpleAttachmentIconContainer>
            {(isLoading && (
              <LoadingIndicator
                actionName="Loading communication attachment"
                htmlId="conversation-simple-item-attachment-loading-indicator"
              />
            )) || <PaperClipIcon />}
          </StyledSimpleAttachmentIconContainer>
          <StyledSimpleAttachmentName baseColors={baseColors}>
            {attachmentName}
          </StyledSimpleAttachmentName>
        </StyledSimpleAttachmentContainer>
      );
    } else if (attachmentType === AttachmentTypeEnum.IMAGE) {
      return (
        <div className={attachmentViewClassName}>
          <div
            className="attachment-view-container"
            data-testid={messageItemAttachmentName + attachmentId}
          >
            <Suspense>
              <ItemAttachmentViewLazy
                attachmentId={attachmentId}
                coxAutoMessageId={coxAutoMessageId}
                fileExtension={extension}
                isDownloadLoading={isLoading}
                onDownload={attachmentHandler}
                onGetBlob={getBlob}
              />
            </Suspense>
          </div>
        </div>
      );
    } else if (
      attachmentType === AttachmentTypeEnum.AUDIO &&
      supportedAudioExtensions.includes(extension)
    ) {
      return (
        <div className={attachmentBubbleClassName}>
          <div
            className="attachment-audio-container"
            data-testid={messageItemAttachmentName + attachmentId}
          >
            <Suspense>
              <ItemAttachmentPlayAudioLazy
                coxAutoMessageId={coxAutoMessageId}
                fileExtension={extension}
                isDownloadLoading={isLoading}
                onDownload={attachmentHandler}
                onGetBlob={getBlob}
              />
            </Suspense>
          </div>
        </div>
      );
    } else if (attachmentType === AttachmentTypeEnum.VIDEO) {
      return (
        <div className={attachmentBubbleClassName} onClick={attachmentHandler}>
          <StyledMessageAttachment>
            <StyledAttachmentIconContainer baseColors={baseColors}>
              <AttachmentIcon
                attachmentType={attachmentType}
                data-testid={messageItemAttachmentIcon + attachmentId}
              />
            </StyledAttachmentIconContainer>
            <StyledAttachmentInfoContainer>
              <StyledAttachmentName
                data-testid={messageItemAttachmentName + attachmentId}
              >
                {new Date(date).toLocaleString()}
              </StyledAttachmentName>
            </StyledAttachmentInfoContainer>
          </StyledMessageAttachment>
        </div>
      );
    } else {
      return (
        <div className={attachmentBubbleClassName} onClick={attachmentHandler}>
          <StyledMessageAttachment>
            <StyledAttachmentIconContainer baseColors={baseColors}>
              <AttachmentIcon
                attachmentType={attachmentType}
                data-testid={messageItemAttachmentIcon + attachmentId}
              />
            </StyledAttachmentIconContainer>
            <StyledAttachmentInfoContainer>
              <StyledAttachmentName
                data-testid={messageItemAttachmentName + attachmentId}
              >
                {attachmentName}
              </StyledAttachmentName>
              {(isLoading && (
                <LoadingIndicator
                  actionName="Loading communication attachment"
                  htmlId="conversation-item-attachment-loading-indicator"
                />
              )) || <IconFileDownload htmlId="IconDownload" />}
            </StyledAttachmentInfoContainer>
          </StyledMessageAttachment>
        </div>
      );
    }
  })();
}
export default ItemAttachment;
