import { ResponseError } from "../interfaces/api-error";

export const getErrorMessage = (response: ResponseError) => {
  return (
    response.errorResult?.message ||
    (response.statusCode && statusCodeToErrorMessage(response.statusCode)) ||
    "Unknown error"
  );
};

function statusCodeToErrorMessage(statusCode: number) {
  return statusCode === 401 ? "Unauthorized" : "Unknown error";
}

export const isResponseError = (
  response: unknown
): response is ResponseError => {
  return (response as ResponseError).isError;
};
